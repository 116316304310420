import { ChevronDownIcon } from "@heroicons/react/20/solid";

/* Components */
import { Button } from "@components/Button";
import {
  ChevronRotate,
  StyledAccordionContent,
  StyledAccordionTrigger,
} from "./styled";

/* Types */
import { ContentProps, TriggerProps } from "./types";

export const AccordionTrigger: React.FC<TriggerProps> = (props) => {
  const { children, width, height, ...rest } = props;
  return (
    <StyledAccordionTrigger
      {...rest}
      className={`${ChevronRotate()} ${rest.className || ""}`}
    >
      {children}
      <Button
        as="a"
        id="chevron"
        className="items-end"
        iconButton
        variant="icon"
      >
        <ChevronDownIcon width={width || 24} height={height || 24} />
      </Button>
    </StyledAccordionTrigger>
  );
};

export const AccordionContent: React.FC<ContentProps> = (props) => {
  const { children } = props;

  return <StyledAccordionContent>{children}</StyledAccordionContent>;
};
