"use client";

import React from "react";
import Link from "next/link";
import Skeleton from "react-loading-skeleton";

/* Components */
import { Text } from "@components";

/* Utils */
import { withSuspense } from "@utils/withSuspense";
import { NavCategories } from "./navCategories";
import { useListCategory } from "@src/service/course";

type Props = {
  isMobile?: boolean;
  onClick?: () => void;
};

const NavigationItems: React.FC<Props> = (props) => {
  const { isMobile, onClick } = props;

  const [categories] = useListCategory();

  return (
    <div className={isMobile ? "flex flex-col gap-12" : "hidden lg:flex gap-8"}>
      <Link onClick={onClick} href="/">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Home
        </Text>
      </Link>
      <Link onClick={onClick} href="/courses">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Learnings
        </Text>
      </Link>

      <Link onClick={onClick} href="/services">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Services
        </Text>
      </Link>

      <Link onClick={onClick} href="/careers">
        <Text as="span" size={isMobile ? "lg" : "base"} weight={500}>
          Careers
        </Text>
      </Link>

      <NavCategories
        categories={categories.data.data || []}
        isMobile={isMobile}
      />
    </div>
  );
};

export const DesktopNavItems = withSuspense<Props>(
  NavigationItems,
  <Skeleton
    containerClassName="hidden lg:flex justify-evenly items-center"
    count={5}
    width={64}
    inline
  />,
);

export const MobileNavItems = withSuspense<Props>(
  NavigationItems,
  <Skeleton
    containerClassName="flex flex-col gap-12 justify-evenly items-center"
    count={5}
    width={64}
    inline
  />,
);
