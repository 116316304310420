import { css, keyframes, styled } from "@styles/stitches";
import * as Expandable from "@radix-ui/react-accordion";

export const ChevronRotate = css({
  "&[data-state='open'] > #chevron": {
    transform: "rotate(180deg)",
  },

  "#chevron": {
    transition: "transform 300ms",
  },
});

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: "var(--radix-accordion-content-height)" },
});

const slideUp = keyframes({
  from: { height: "var(--radix-accordion-content-height)" },
  to: { height: 0 },
});

export const StyledAccordionContent = styled(Expandable.Content, {
  padding: "1rem",
  overflow: "hidden",
  opacity: 1,

  '&[data-state="open"]': {
    display: "flex",
    flexDirection: "column",
    animation: `${slideDown} 300ms ease-in-out`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms ease-in-out`,
    padding: 0,
    opacity: 0,
  },
});

export const StyledAccordionItem = styled(Expandable.Item, {
  "&:not(:last-child)": {
    marginBottom: "1rem",
  },
  overflow: "hidden",
});

export const StyledAccordionTrigger = styled(Expandable.Trigger, {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "0.5rem",
  width: "100%",
});
