import Link from "next/link";
import cn from "classnames";
import { ChevronDown } from "lucide-react";
import React, { useEffect, useState } from "react";
import * as Popover from "@radix-ui/react-popover";

/* Components */
import { Button } from "@components";
import { Accordion } from "@components/Accordion";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* CSS */
import { popoverWrapper, popoverContainer, removeOutline } from "./styled.css";

/* Types */
import type { CategoryType } from "@src/service/course";

type Props = {
  children?: React.ReactNode;
  isMobile?: boolean;
  categories: CategoryType[];
};

type PopoverProps<T = boolean> = {
  open: T;
  categories: CategoryType[];
};

type ListItemsProps = {
  categories: CategoryType[];
  uniqueIdPrefix: string;
};

export const NavCategories: React.FC<Props> = (props) => {
  const { isMobile, categories } = props;

  /* States */
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        as="button"
        variant="link"
        onMouseOver={() => !isMobile && setOpen(true)}
        onMouseOut={() => !isMobile && setOpen(false)}
        onClick={() => isMobile && setOpen(!open)}
        noPadding
        className={cn("!no-underline", { "!w-full !items-start": isMobile })}
        rightIcon={() => (
          <ChevronDown
            className={`stroke-primary-text mt-1 transition-all duration-200 ease-out ${
              open ? "rotate-180" : "rotate-0"
            }`}
            size={24}
          />
        )}
        typographyProps={{
          as: "div",
          size: isMobile ? "lg" : "base",
          weight: 500,
          className: "w-3/5",
        }}
      >
        Categories
        {!isMobile && <DesktopPopover categories={categories} open={open} />}
        {isMobile && <MobileAccordion categories={categories} open={open} />}
      </Button>
    </>
  );
};

const MobileAccordion: React.FC<PopoverProps> = (props) => {
  const { open, categories } = props;

  /* Memos */
  const unquieId = useUniqueId("mobile-nav-categories-");
  const [stringOpen, setStringOpen] = useState("open");

  useEffect(() => {
    if (open) setStringOpen("mobile-categories-item");
    else setStringOpen("");
  }, [open]);

  return (
    <Accordion.Single value={stringOpen}>
      {(Item) => (
        <Item value="mobile-categories-item">
          <Item.Content>
            <div className="w-full flex flex-col items-center">
              <ListItems categories={categories} uniqueIdPrefix={unquieId} />
            </div>
          </Item.Content>
        </Item>
      )}
    </Accordion.Single>
  );
};

const DesktopPopover: React.FC<PopoverProps> = (props) => {
  const { open, categories } = props;
  /* Memos */
  const unquieId = useUniqueId("desktop-nav-categories-");

  return (
    <Popover.Root open={open}>
      <Popover.Anchor />
      <Popover.Portal>
        <Popover.Content className={popoverWrapper}>
          <div className={`${popoverContainer} ${removeOutline}`}>
            <ListItems categories={categories} uniqueIdPrefix={unquieId} />
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const ListItems: React.FC<ListItemsProps> = (props) => {
  const { categories, uniqueIdPrefix } = props;
  return (
    <>
      {categories.map((c) => (
        <Link
          className={removeOutline}
          key={uniqueIdPrefix + c.slug}
          href={`/courses?categories=${c.slug}`}
        >
          <Button
            variant="link"
            typographyProps={{ color: "white" }}
            className={`!no-underline ${removeOutline}`}
          >
            {c.name}
          </Button>
        </Link>
      ))}
    </>
  );
};
