"use client";

import Link from "next/link";
import React, { useMemo } from "react";

/* Components */
import { Logo } from "@src/assets/logo";
import { Heading, Text } from "@components";

/* CSS */
import { footerCls } from "./style.css";

/* Utils */
import { FormatDate } from "@utils/dates-fns";

/* Data */
import * as footerData from "./data.json";
import { textUtils } from "@utils/text";

type Props = {
  children?: React.ReactNode;
};

const HomeFooter: React.FC<Props> = (props) => {
  const {} = props;

  /* Memos */
  const currentYear = useMemo(() => FormatDate(new Date(), "yyyy"), []);

  return (
    <div className="mt-24 pt-6 bg-neutral-gray">
      <div className={`container ${footerCls.wrapper}`}>
        <div>
          <Logo fontSize="6rem" />
        </div>

        <div className={footerCls.contentW}>
          <div className={footerCls.contentC}>
            <Heading className="mb-2" size="lg">
              Coporate & Communication Address:
            </Heading>

            {Object.entries(footerData.addresses).map(([key, value], i) => (
              <Text key={key + i} className="pl-4">
                {textUtils.capitalize(key) + " Office Location: " + value}
              </Text>
            ))}
          </div>

          <div className={footerCls.contentC}>
            <Heading className="mb-2" size="lg">
              Email & Phone Numbers:
            </Heading>
            <Text
              as="a"
              href={`mailto:${footerData.email}`}
              className="pl-4 underline"
            >
              {footerData.email}
            </Text>

            {footerData.phone.map((phone, i) => (
              <Text
                as="a"
                href={`tel:${phone}`}
                key={phone + i}
                className="pl-4 underline"
              >
                {phone}
              </Text>
            ))}
          </div>

          <div className={footerCls.contentC}>
            <Heading className="mb-2" size="lg">
              Important Links
            </Heading>
            <Link href="/pricing">
              <Text as="span" className="underline" size="base" weight={500}>
                Pricing
              </Text>
            </Link>

            <Link href="/projects">
              <Text as="span" size="base" className="underline" weight={500}>
                Projects
              </Text>
            </Link>
          </div>
        </div>

        <Text>{`Copyright © ${currentYear}. Powered by Moss Tech.`}</Text>
      </div>
    </div>
  );
};

export default HomeFooter;
